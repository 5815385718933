.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000a3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lightbox__content {
    max-width: 1000px;
    height: 100%;
    max-height: 100%;
    width: 100%;
    padding: 3em 5em;
    position: relative;
    display: flex;
    align-items: center;
}

.lightbox__content__image-container {
    overflow: auto;
    max-height: 100%;
    width: 100%;
    border-radius: 5px;
}

.lightbox__content__image-container::-webkit-scrollbar-thumb {
    background: white;
}

img.lightbox__content__image-container__image {
    width: 100%;
    padding: 0em .5em;
}

.lightbox__content__prev,
.lightbox__content__next {
    display:flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    height: 50px;
    width: 50px;
    font-size: 2em;
    color: white;
    cursor: pointer;

}

.lightbox__content__prev:hover,
.lightbox__content__prev:focus,
.lightbox__content__next:hover,
.lightbox__content__next:focus {
    color: gray;
}

.lightbox__content__prev.disabled,
.lightbox__content__next.disabled {
    opacity: .5;
    pointer-events: none;
}

.lightbox__content__prev {
    transform: translate(-125%, -50%);
}

.lightbox__content__next {
    right: 0;
    transform: translate(-25%, -50%);
}

.lightbox__content__pager {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-width: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -25%);
    padding: 1em;
}

.lightbox__content__pager__indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15px;
    width: 15px;
    border-radius: 100%;
    cursor: pointer;
    background: white;
}

.lightbox__content__pager__indicator:hover,
.lightbox__content__pager__indicator:focus {
    background: gray;
}

.lightbox__content__pager__indicator.active {
    background: var(--accent-colour);
}