img.portfolio__home__content__showcase__case__image {width: 100%;height: 100%;object-fit: cover;}

.portfolio__home {
    height: 100%;
    display: flex;
    column-gap: 3em;
    row-gap: 1em;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 1em;
}

h1.portfolio__home__heading {
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 3em;
    color: white;
}

.portfolio__home__content__showcase {
    display: flex;
    gap: 1em;
    justify-content: center;
    align-items: center;
    padding: 0em 3em;
}

.portfolio__home__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.portfolio__home__content__showcase__case {
    height: 17em;
    width: 12em;
    transition: height 0.15s ease-out, width 0.15s ease-out;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
}

.portfolio__home__content__showcase__case:hover,.portfolio__home__content__showcase__case:focus {
    font-size: 1.5em;
    box-shadow: 0px 0px 6px 0px var(--accent-colour);
    border: 1px solid rgba(255, 255, 255, 0.473);
}

.portfolio__home__content__showcase__button-group {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (max-width: 1200px) {
    .portfolio__home {
        font-size: .8em;
    }
}

@media screen and (max-width: 992px) {
    .portfolio__home {
        flex-direction: column;
    }
    .portfolio__home__content__showcase {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 768px) {
    .portfolio__home__content__showcase {
        font-size: .8em;
    }
}

@media screen and (max-width: 576px) {
    .portfolio__home {
        justify-content: start;
    }

    h1.portfolio__home__heading {
        font-size: 2.5em
    }
}