.portfolio {
    height: 100%;
    display: grid;
    grid-template-rows: 60px calc(100% - 60px);
    background-image: url(./images/SpaceMcall.jpg);
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(9, 15, 35, 0.91);
}


.portfolio__body {
    height: 100%;
    overflow: auto;
}

.portfolio__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-colour);
}

img.portfolio__nav__logo {
    max-height: 100%;
    max-width: 60px;
    cursor: pointer;
}

.portfolio__nav__content {
    padding: 0em 1em;
    display: flex;
    gap: 2em;
}

.portfolio__nav__content__item {
    font-weight: 500;
    font-family: var(--primary-font);
    color: var(--secondary-colour);
    position: relative;
}

.portfolio__nav__content__item.active::before {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    width: 100%;
    height: 3px;
    border-radius: 100px;
    background-color: var(--secondary-colour);
}