.portfolio__cases {
    display: grid;
    grid-template-columns: 200px 1fr;
    height: 100%;
    padding: 1em 0em;
}

.portfolio__cases_container {
    height: 100%;
    position: relative;
    overflow: auto;
    max-height: 100%;
    padding: 0em 0.5em;
    padding-top: 0;
    margin-right: .5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.portfolio__case__content__heading {
    padding-bottom: .25em;
    display: flex;
    gap: 1em;
    align-items: center;
}

.portfolio__case__content__heading__link {
    font-size: 1.5em;
    display: flex;
    padding-bottom: .25em;
    cursor: pointer;
}

.portfolio__case__content__heading__link:hover,
.portfolio__case__content__heading__link:focus {
    color: gray;
}

@media screen and (max-width: 992px) {
    .portfolio__cases {
        grid-template-columns: 1fr;
    }
}